import React, { FC, memo, useEffect } from 'react'
import { PageProps } from 'gatsby'
import { TailSpin } from 'react-loader-spinner'

import { catalogAPI } from '@api/catalog'

import Layout from '@components/layout'
import { customNavigate, getLastPathElem } from '@utils/utils'
import { headers } from '@utils/constants'

interface IServerDataType {
  cityWithBrokerId: string
}

const Broker: FC<PageProps<object, object, unknown, IServerDataType>> = ({
  path,
  serverData: { cityWithBrokerId },
}) => {
  useEffect(() => void customNavigate(`${path}${cityWithBrokerId}`), [])

  return (
    <Layout center>
      <TailSpin color="#00AD64" height={30} width={30} />
    </Layout>
  )
}

export async function getServerData() {
  try {
    const cities = (await catalogAPI.getCities(false, true)) || []

    let firstCityWithBrokerId = '0'
    for (let city of cities) {
      if (firstCityWithBrokerId !== '0') break

      const agency = await catalogAPI.getBrokers(false, getLastPathElem(city['@id']))
      if (agency.data.length) firstCityWithBrokerId = getLastPathElem(city['@id'])
    }

    return {
      props: {
        cityWithBrokerId: firstCityWithBrokerId,
      },
      headers,
    }
  } catch (e) {
    return {
      props: {
        cityWithBrokerId: '0',
      },
      headers,
    }
  }
}

export default memo(Broker)
